import React from 'react';
import { Rings } from 'react-loader-spinner';
import '../assets/css/spinner.css';

const Spinner = () => {
  return (
    <div className='spinnerbackground'>
      <Rings
        height="80"
        width="80"
        radius="9"
        color='var(--current-color, var(--color-1))'
        ariaLabel='three-dots-loading'
        wrapperStyle={{}} // Ensure this is an object
        wrapperClass=""
      />
    </div>
  );
}

export default Spinner;
